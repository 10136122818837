<template>
  <div class="register-container">
    <v-card
      elevation="2"
      :loading="authInProgress || loading"
      class="register-card"
    >
      <div>
        <div class="d-flex align-center justify-center pa-10">
          <v-img
            :src="require(`../../assets/${logoSrc}`)"
            :lazy-src="require(`../../assets/${logoSrc}`)"
            :max-height="200"
            :max-width="200"
          />
        </div>
        <v-card-title class="pt-0">Registration</v-card-title>
        <v-card-subtitle>{{ appTitle }}</v-card-subtitle>
      </div>
      <RegistrationStepper @onLoading="loading = $event" />
    </v-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { Vue } from "vue-property-decorator";
import router from "@/router";
import { dismissAction, logAndExtractMessage } from "@/utils/utils";
import theme from "../../../app-theme.config";
import RegistrationStepper from "./registration-steps/RegistrationStepper";
import { IS_MOBILE } from "@/utils/is-mobile";

export default {
  name: "Registration",

  components: {
    RegistrationStepper,
  },

  data: () => ({
    appTitle: theme.title,
    logoSrc: theme.logoSrc,
    loading: false,
  }),

  computed: {
    ...mapState("userModule", ["authInProgress"]),
    ...mapState("coreModule", ["drawer"]),
  },

  mounted() {
    if (!IS_MOBILE && this.drawer) this.$store.commit("coreModule/toggleDrawer");

    const errorDescription = router.currentRoute.query.error_description;

    if (errorDescription) {
      Vue.toasted.error(logAndExtractMessage(errorDescription), { ...dismissAction });
    } else {
      this.$store.dispatch("userModule/checkOnRedirect").catch((e) => {
        Vue.toasted.error(logAndExtractMessage(e), { ...dismissAction });
      });
    }
  },
};
</script>

<style scoped lang="scss">
.register-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

@media (max-width: 960px) {
  .register-container {
    .register-card {
      flex-direction: column;
    }
  }
}
</style>
