<template>
  <div class="email-password-step d-flex flex-column justify-space-between h-100">
    <div class="pl-4 pr-4">
      <v-text-field
        label="Email"
        :disabled="pLoading"
        v-model="email"
        :rules="[validationEmail]"
        @input="onInput"
      ></v-text-field>
      <v-text-field
        label="Password"
        :disabled="pLoading"
        v-model="password"
        :rules="[validationPassword]"
        type="password"
        @input="onInput"
      ></v-text-field>
      <v-text-field
        :disabled="pLoading"
        label="Repeat password"
        v-model="passwordCopy"
        @input="onInput"
        :rules="[validationPasswordCopy]"
        type="password"
      ></v-text-field>
    </div>
  </div>
</template>

<script>
import { EMAIL_REGEX } from "../../../utils/utils";

export default {
  name: "EmailPasswordStep",

  props: {
    pLoading: Boolean,
  },

  data: () => ({
    email: "",
    password: "",
    passwordCopy: "",
  }),

  computed: {
    validationEmail() {
      return (
        EMAIL_REGEX.test(this.email)
        || "Must be a valid email address"
      );
    },

    validationPassword() {
      return (
        this.password.length > 8
        || "Must be at least 8 symbols"
      );
    },

    validationPasswordCopy() {
      if (this.passwordCopy.length < 8) {
        return "Must be at least 8 symbols";
      }

      if (this.password !== this.passwordCopy) {
        return "Passwords must be equal!";
      }

      return true;
    },

    disableContinue() {
      return !this.email
        || !this.password
        || !this.passwordCopy
        || this.validationEmail !== true
        || this.validationPassword !== true
        || this.validationPasswordCopy !== true;
    },
  },

  methods: {
    onInput() {
      this.$emit("onInput", {
        email: this.email,
        password: this.password,
        disabled: this.disableContinue,
      });
    },
  },
};
</script>

<style lang="scss">
.email-password-step {
  min-height: inherit;
}
</style>
