import moment from "moment";
import type { DurationInputArg1, DurationInputArg2 } from "moment";

export type Timer = {
  time: string | null;
  clear?: () => void;
}

export const timerMixin = {
  methods: {
    getTimeFromDuration(durationMoment: moment.Duration): string {
      const min = durationMoment.minutes();
      const sec = durationMoment.seconds();
      return `${min < 10 ? "0" : ""}${min}:${sec < 10 ? "0" : ""}${sec}`;
    },

    startTimer(timer: Timer, amount: DurationInputArg1, unit: DurationInputArg2, cbAfterTimeout?: () => void): void {
      if (timer.clear) {
        timer.clear();
      }
      const currentTime = moment().unix();
      const eventTime = moment().add(amount, unit).unix();
      const diffTime = eventTime - currentTime;
      let durationMoment = moment.duration(diffTime * 1000, "milliseconds");
      timer.time = this.getTimeFromDuration(durationMoment);

      let interval: any;

      timer.clear = (): void => {
        timer.time = null;
        clearInterval(interval);
      };

      interval = setInterval(() => {
        const duration = durationMoment as any - 1000;
        if (!duration) {
          if (cbAfterTimeout) {
            cbAfterTimeout();
          }
          if (timer.clear) {
            timer.clear();
          }
          return;
        }
        durationMoment = moment.duration(duration, "milliseconds");
        timer.time = this.getTimeFromDuration(durationMoment);
      }, 1000);
    },
  },
};
