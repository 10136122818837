<template>
  <div class="registration-stepper d-flex flex-column justify-space-between">
    <v-stepper
      v-model="step"
      :loading="loading"
      elevation="0"
      width="100%"
      class="stepper"
    >
      <v-stepper-header>
        <v-stepper-step
          v-for="it of stepHeaders"
          :key="it.step"
          v-show="step === it.step"
          :complete="it.complete(step)"
          :step="it.step"
        >
          {{it.text}}
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items class="h-100">
        <v-stepper-content class="pa-0 pt-2 pb-2 h-100" step="1">
          <EmailPasswordStep
            :pLoading="loading"
            @onInput="onInputEmailPassword"
          />
        </v-stepper-content>

        <v-stepper-content class="pa-0 pt-2 pb-2 h-100" step="2">
          <ConfirmationCodeStep
            :pLoading="loading"
            @onInput="onInputCode"
            @onResendVerificationCode="resendVerificationCode"
          />
        </v-stepper-content>

      </v-stepper-items>
    </v-stepper>
    <div class="d-flex pl-2 pr-2 pb-4">
      <v-btn
        v-if="step === 1"
        class="go-to-login-btn pl-1 pr-1"
        text
        color="primary"
        @click="routeToLogin"
      >Log in</v-btn>
      <v-spacer/>
      <v-spacer />
      <v-btn
        v-if="step === 1"
        class="continue-btn pl-1 pr-1"
        color="primary"
        :disabled="emailPasswordConfirmDisabled || loading"
        @click="onSetEmailPassword"
      >
        Continue
      </v-btn>
      <v-btn
        v-if="step === 2"
        class="continue-btn pl-1 pr-1"
        color="primary"
        :disabled="codeConfirmDisabled || loading"
        @click="onConfirmCode"
      >
        Confirm
      </v-btn>
    </div>
  </div>
</template>

<script>
import { Vue } from "vue-property-decorator";
import EmailPasswordStep from "./EmailPasswordStep";
import ConfirmationCodeStep from "./ConfirmationCodeStep";
import { dismissAction, logAndExtractMessage } from "@/utils/utils";

export default {
  name: "RegistrationStepper",

  components: {
    ConfirmationCodeStep,
    EmailPasswordStep,
  },

  data() {
    return {
      loading: false,

      step: 1,

      email: "",
      password: "",
      code: "",

      emailPasswordConfirmDisabled: true,
      codeConfirmDisabled: true,

      stepHeaders: [
        {
          step: 1,
          text: "Email and password",
          complete: (step) => step > 1,
        },
        {
          step: 2,
          text: "Confirmation code",
          complete: (step) => step > 2,
        },
      ],
    };
  },

  methods: {
    startLoading() {
      this.loading = true;
      this.$emit("onLoading", this.loading);
    },

    endLoading() {
      this.loading = false;
      this.$emit("onLoading", this.loading);
    },

    async resendVerificationCode() {
      try {
        await this.$store.dispatch("userModule/resendSignUp", { email: this.email });
      } catch (e) {
        Vue.toasted.error(logAndExtractMessage(e), { ...dismissAction });
      }
    },

    onInputEmailPassword(data) {
      this.email = data.email;
      this.password = data.password;
      this.emailPasswordConfirmDisabled = data.disabled;
    },

    async onSetEmailPassword() {
      this.startLoading();

      try {
        await this.$store.dispatch("userModule/signUp", {
          email: this.email,
          password: this.password,
        });
        this.step = 2;
      } catch (e) {
        Vue.toasted.error(logAndExtractMessage(e), { ...dismissAction });
      }

      this.endLoading();
    },

    onInputCode(data) {
      this.code = data.code;
      this.codeConfirmDisabled = data.disabled;
    },

    async onConfirmCode() {
      this.startLoading();

      try {
        await this.$store.dispatch("userModule/confirmRegistration", {
          email: this.email,
          code: this.code,
          hostId: this.hostId,
        });

        // We store only e-mail in the storage.
        // Thus, if the user closes the OTP page and then opens it again and enters it,
        // he needs to log in, but we do not know his password.
        if (!this.password) {
          await this.$router.push("/login");
          return;
        }

        await this.$store.dispatch("userModule/signIn", {
          email: this.email,
          password: this.password,
        });
      } catch (e) {
        Vue.toasted.error(logAndExtractMessage(e), { ...dismissAction });
      }

      this.endLoading();
    },

    routeToLogin() {
      this.$router.push({ path: "/login", query: this.$route.query });
    },
  },

  mounted() {
    const { hostId, step, email } = this.$route.query;
    this.hostId = hostId;
    this.step = +step || 1;
    this.email = email || "";
  },
};
</script>

<style lang="scss">
.registration-stepper {
  min-height: 100% !important;
  border-top: 1px solid var(--v-superLightSecondary-base);

  .stepper {
    min-width: 500px;
    .v-stepper__header {
      box-shadow: none !important;
      border-bottom: 1px solid var(--v-superLightSecondary-base);
    }
  }
  .v-stepper__step__step {
    margin-right: 8px !important;
  }
  .v-stepper__label {
    display: block !important;
  }
}
@media (max-width: 960px) {
  .registration-stepper {
    box-shadow: none;
  }
}

@media (max-width: 600px) {
  .registration-stepper {
    box-shadow: none;

    .stepper {
      min-width: 350px;
    }
  }
}
</style>
