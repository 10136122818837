<template>
  <div class="confirmation-code-step">
    <div class="pl-4 pr-4">
      <div>Paste the code that was sent to your email.</div>
      <div
        class="resend-otp"
        v-if="otpTimer.time"
      >After {{otpTimer.time}} you can resend email with OTP.</div>
      <div
        v-else
        class="resend-otp active"
        @click="resendVerificationCode"
      >Resend OTP</div>

      <v-text-field
        class="mb-2"
        label="Code"
        :disabled="pLoading"
        v-model="code"
        :rules="[validationCode]"
        type="password"
        @input="onInput"
      ></v-text-field>
    </div>
  </div>
</template>

<script>
import { timerMixin } from "@/components/mixins/timer";

export default {
  name: "ConfirmationCodeStep",

  props: {
    pLoading: Boolean,
  },

  mixins: [timerMixin],

  data: () => ({
    code: "",
    otpTimer: { time: null },
  }),

  computed: {
    validationCode() {
      if (Number.isNaN(+this.code)) {
        return "Only numbers are allowed";
      }

      return (
        this.code.length === 6
        || "Must be 6 symbols long"
      );
    },

    disableConfirm() {
      return !this.code || this.validationCode !== true;
    },
  },

  methods: {
    onInput() {
      this.$emit("onInput", {
        code: this.code,
        disabled: this.disableConfirm,
      });
    },

    resendVerificationCode() {
      this.$emit("onResendVerificationCode");
      this.restartOtpTimer();
    },

    restartOtpTimer() {
      this.startTimer(this.otpTimer, 1, "minute");
    },
  },

  mounted() {
    this.restartOtpTimer();
  },
};
</script>

<style scoped lang="scss">
.resend-otp {
  font-size: 13px;
  color: grey;

  &.active {
    color: var(--v-primary-base);
    cursor: pointer;
    text-decoration: underline;
    transition: color .2s;
  }
}
</style>
